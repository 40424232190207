import React, { lazy, Suspense } from 'react'
import { Redirect, Switch, BrowserRouter as Router, Route } from 'react-router-dom'
import LoadingIndicator from '../components/LoadingIndicator'


const HomeView = lazy(() => import('../views/HomeView'))
const ResultsView = lazy(() => import('../views/ResultsView'))
const SavedView = lazy(() => import('../views/SavedView'))
const JsonViewer = lazy(() => import('../components/JsonViewer'))
const EraserView = lazy(() => import('../views/EraserView'))
const Login = lazy(() => import('../components/Login'))

export default function Routes() {
    return (
        <Router>
            <Suspense fallback={<div style={{ minHeight: '60vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><LoadingIndicator loadingText={"Cargando"} /></div>}>
                <Switch>
                    <Route exact path={`/`} component={HomeView} />
                    <Route path={`/results`} component={ResultsView} />
                    <Route path={`/saved`} component={SavedView} />
                    <Route path={`/eraser`} component={EraserView} />                    
                    <Route exact path="/jsonviewer/:id" component={JsonViewer} />
                    <Route path={`/login`} component={Login} />
                    <Route path={`/*`}>
                        <Redirect to={`/`} />
                    </Route>
                   
                </Switch>
            </Suspense>
        </Router>
    )
}
