import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import TesauroSiisLogo from '../../../assets/icons/logo-tesauro-ssoc.png'
import BookManual from '../../../assets/icons/book-manual.svg'
import "./navbar.scss";

function NavBar() {
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <div className='app-bar'>
            <Container maxWidth="none">
                <Toolbar disableGutters>
                    <Box sx={{ flexGrow: 0 }}>
                        <a href='/'>
                            <img className='tesauro-siis-logo' alt="Tesauro Siis Logo" src={TesauroSiisLogo}/>
                        </a>
                    </Box>
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'none' }, justifyContent: 'flex-end' }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="blue"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',

                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', sm: 'none' }, minWidth: 180,  '& .MuiPaper-root': {borderRadius: 2, minWidth: '210px'}, '& .MuiList-root': {paddingTop: '0px', paddingBottom: '0px'},'& .MuiMenu-list': {paddingTop: '0px', paddingBottom: '0px', display: 'flex', justifyContent: 'center' },
                            }}
                        >
                            <MenuItem onClick={handleCloseNavMenu}>
                                <button className="btn_primary btn-manual" onClick={() =>
                                window.open(`https://www.supersociedades.gov.co/tesauro-pedagogia`)
                                }
                                alt="Manual"
                                >
                                    <img className='book-manual' alt="Manual de usuario" src={BookManual}/>
                                    MANUAL DE USUARIO
                                </button>
                            </MenuItem>
                        </Menu>
                    </Box>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' }, justifyContent: 'flex-end' }}>
                        <button className="btn_primary btn-manual" onClick={() =>
                        window.open(`https://www.supersociedades.gov.co/tesauro-pedagogia`)
                        }
                        alt="Manual"
                        >
                            <img className='book-manual' alt="Manual de usuario" src={BookManual}/>
                            MANUAL DE USUARIO
                        </button>
                    </Box>
                </Toolbar>
            </Container>
        </div>
    );
}
export default NavBar;