import React from 'react';
import './TopBar.scss';
import logo_gov_top from '../../../assets/icons/logo_gov.png'

const TopBar = (props) => {

  return (
    <>
      <div className='top-bar-container'>
        <img
          alt="gov.co"
          className="logo-top-bar"
          src={logo_gov_top}
        ></img>
        <a className="link-gov" target="_blank" href="https://www.gov.co">Ir a Gov.co</a>
      </div>
    </>
  );
};

export default TopBar;
