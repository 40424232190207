import React, { createContext, useContext, useState, useEffect } from 'react';
import { InitPage } from '../backend/init_page';

// Crear el contexto para contentTypes
const ContentTypesContext = createContext();

// Hook personalizado para usar el contexto
export const useContentTypes = () => useContext(ContentTypesContext);

// Proveedor del contexto
export const ContentTypesProvider = ({ children }) => {
  const [contentTypes, setContentTypes] = useState(null);

  useEffect(() => {
    // Llamada a InitPage solo para obtener contentTypes
    InitPage().then((resp) => {
      setContentTypes(resp.contentType); // Solo guardamos contentTypes
    }).catch(console.error);
  }, []);

  return (
    <ContentTypesContext.Provider value={contentTypes}>
      {children}
    </ContentTypesContext.Provider>
  );
};