/* eslint-disable new-parens */
import axios from "axios";
import { API_FILTERS, API_CONTENT_TYPES } from "./settings";
import { API_DESCRIPTORES } from "./settings";

export function SortArray(x, y) {
    const collator = new Intl.Collator("sp");
    return collator.compare(x.label, y.label);
}

export function get(type) {
    let query = {
        size: 0,
        aggregations: {
            Estado: {
                terms: {
                    field: type,
                    size: 1000,
                },
            },
        },
    };

    return axios
        .get(API_FILTERS, {
            params: {
                source: JSON.stringify(query),
                source_content_type: "application/json",
            },
        })
        .then((res) => {
            let arrTmp = [];
            res.data.aggregations.Estado.buckets.forEach((element) => {
                arrTmp.push({
                    label: element.key,
                    value: element.key,
                });
            });

            const arrSortCollocator = arrTmp.sort(SortArray);

            // const arrSort = arrTmp.sort(function (a, b) {
            //     if (a.label.toLowerCase() > b.label.toLowerCase()) {
            //         return 1;
            //     }
            //     if (a.label.toLowerCase() < b.label.toLowerCase()) {
            //         return -1;
            //     }
            //     return 0;
            // });

            return arrSortCollocator;
        });
}

export function getTypeContents() {
    return axios
        .get(API_CONTENT_TYPES)
        .then((res) => {
            // Validamos si 'hits' existe en la respuesta y tiene datos
            if (res.data.hits && res.data.hits.hits.length > 0) {
                // Mapear sobre los hits para obtener todos los '_source'
                const contents = res.data.hits.hits.map(hit => hit._source);
                return contents; // Retorna los resultados
            } else {
                throw new Error("No se encontraron tipos de contenido.");
            }
        })
        .catch((error) => {
            console.error("Error al obtener los tipos de contenido:", error);
            throw error;
        });
}

export function getSecDescriptors(principalDescriptor) {
    let query = {
        query: {
            bool: {
                must: [
                    {
                        term: {
                            "descriptores.descriptor_principal.keyword":
                                principalDescriptor,
                        },
                    },
                ],
            },
        },
        _source: [
            "id_relatoria",
            "descriptores.descriptor_principal",
            "descriptores.descriptores_secundarios",
        ],
    };

    return axios
        .get(API_FILTERS, {
            params: {
                source: JSON.stringify(query),
                source_content_type: "application/json",
            },
        })
        .then((res) => {
            let arrTmp = [];
            res.data.hits.hits.forEach((element) => {
                element._source.descriptores.forEach((element) => {
                    if (element.descriptor_principal === principalDescriptor) {
                        element.descriptores_secundarios.forEach(
                            (secDescriptors) => {
                                arrTmp.push({
                                    label: secDescriptors.nombre_descriptor,
                                    value: secDescriptors.nombre_descriptor,
                                });
                            }
                        );
                    }
                });
            });
            let set = new Set(arrTmp.map(JSON.stringify));
            let arrSinDuplicaciones = Array.from(set).map(JSON.parse);
            return arrSinDuplicaciones;
        });
}

export function getThemeProblems() {
    let query = {
        size: 0,
        query: {
            bool: {
                filter: [
                    {
                        match: {
                            "informacion.tipo_contenido": {
                                query: "Temas y problemas",
                                operator: "and",
                            },
                        },
                    },
                ],
            },
        },
        aggregations: {
            Estado: {
                terms: {
                    field: "titulo.keyword",
                    size: 1000,
                },
            },
        },
    };

    return axios
        .get(API_FILTERS, {
            params: {
                source: JSON.stringify(query),
                source_content_type: "application/json",
            },
        })
        .then((res) => {
            let arrTmp = [];
            res.data.aggregations.Estado.buckets.forEach((element) => {
                arrTmp.push({
                    label: element.key,
                    value: element.key,
                });
            });

            const arrSort = arrTmp.sort(function (a, b) {
                if (a.label.toLowerCase() > b.label.toLowerCase()) {
                    return 1;
                }
                if (a.label.toLowerCase() < b.label.toLowerCase()) {
                    return -1;
                }
                return 0;
            });

            return arrSort;
        });
}

export function getProblemsByTheme(theme = "*") {
    let query = {
        size: 0,
        query: {
            bool: {
                filter: [
                    {
                        match: {
                            "titulo.keyword": {
                                query: theme,
                                operator: "and",
                            },
                        },
                    },
                ],
            },
        },
        aggregations: {
            Estado: {
                terms: {
                    field: "problemas_juridicos_temas.pregunta.keyword",
                    size: 1000,
                },
            },
        },
    };

    return axios
        .get(API_FILTERS, {
            params: {
                source: JSON.stringify(query),
                source_content_type: "application/json",
            },
        })
        .then((res) => {
            let arrTmp = [];
            res.data.aggregations.Estado.buckets.forEach((element) => {
                arrTmp.push({
                    label: element.key,
                    value: element.key,
                });
            });

            const arrSort = arrTmp.sort(function (a, b) {
                if (a.label.toLowerCase() > b.label.toLowerCase()) {
                    return 1;
                }
                if (a.label.toLowerCase() < b.label.toLowerCase()) {
                    return -1;
                }
                return 0;
            });

            return arrSort;
        });
}

export function getMatterByTheme(theme = "*") {
    let query = {
        size: 0,
        query: {
            bool: {
                filter: [
                    {
                        match: {
                            "informacion.tema": {
                                query: theme,
                                operator: "and",
                            },
                        },
                    },
                ],
            },
        },
        aggregations: {
            Estado: {
                terms: {
                    field: "titulo.keyword",
                    size: 1000,
                },
            },
        },
    };

    return axios
        .get(API_FILTERS, {
            params: {
                source: JSON.stringify(query),
                source_content_type: "application/json",
            },
        })
        .then((res) => {
            let arrTmp = [];
            res.data.aggregations.Estado.buckets.forEach((element) => {
                arrTmp.push({
                    label: element.key,
                    value: element.key,
                });
            });

            const arrSort = arrTmp.sort(function (a, b) {
                if (a.label.toLowerCase() > b.label.toLowerCase()) {
                    return 1;
                }
                if (a.label.toLowerCase() < b.label.toLowerCase()) {
                    return -1;
                }
                return 0;
            });

            return arrSort;
        });
}

export function getSecondDescriptorByPrincipal(principalDescriptor) {
    let query = {
        size: 0,
        query: {
            bool: {
                filter: [
                    {
                        match: {
                            "descriptores.descriptor_principal.keyword": {
                                query: principalDescriptor,
                                operator: "and",
                            },
                        },
                    },
                ],
            },
        },
        aggregations: {
            Estado: {
                terms: {
                    field: "descriptores.descriptor_secundario.keyword",
                    size: 1000,
                },
            },
        },
    };

    return axios
        .get(API_FILTERS, {
            params: {
                source: JSON.stringify(query),
                source_content_type: "application/json",
            },
        })
        .then((res) => {
            let arrTmp = [];
            res.data.aggregations.Estado.buckets.forEach((element) => {
                arrTmp.push({
                    label: element.key,
                    value: element.key,
                });
            });

            const arrSort = arrTmp.sort(function (a, b) {
                if (a.label.toLowerCase() > b.label.toLowerCase()) {
                    return 1;
                }
                if (a.label.toLowerCase() < b.label.toLowerCase()) {
                    return -1;
                }
                return 0;
            });

            return arrSort;
        });
}

export function getSecondDescriptorByPrincipalInnerHits(descriptor) {
    let query = {
        size: 10000,
        query: {
            nested: {
                path: "tesauro_filtro",
                query: {
                    bool: {
                        filter: [
                            {
                                match: {
                                    "tesauro_filtro.descriptor_principal":
                                        descriptor,
                                },
                            },
                        ],
                    },
                },
                inner_hits: {
                    size: 100,
                    _source: [
                        "tesauro_filtro.descriptores_secundarios",
                        "tesauro_filtro.descriptor_principal",
                    ],
                },
            },
        },
        _source: ["id_relatoria"],
    };

    return axios
        .get(API_FILTERS, {
            params: {
                source: JSON.stringify(query),
                source_content_type: "application/json",
            },
        })
        .then((res) => {
            let arrTmp = [];
            res.data.hits.hits.forEach((element) => {
                element.inner_hits.tesauro_filtro.hits.hits.forEach(
                    (element) => {
                        if (
                            element._source.descriptor_principal === descriptor
                        ) {
                            arrTmp.push({
                                label: element._source.descriptores_secundarios,
                                value: element._source.descriptores_secundarios,
                            });
                        }
                    }
                );
            });
            let set = new Set(arrTmp.map(JSON.stringify));
            let arrSinDuplicaciones = Array.from(set).map(JSON.parse);

            return arrSinDuplicaciones;
        });
}

//LISTA DE DESCRIPTORES + VÉASE Y BÚSQUESE
export function getHierarchicalDescriptorList(searchText) {
    let query = {
        from: 0,
        size: 10000,
        query: {
            bool: {
                should: [
                    {
                        query_string: {
                            fields: ["nombre"],
                            query: "*" + searchText + "*"
                        }
                    },
                    {
                        query_string: {
                            fields: ["descriptores_secundarios.nombre"],
                            query: "*" + searchText + "*"
                        }
                    }
                ],
                boost: 1
            }
        }
    };

    return axios
        .get(API_DESCRIPTORES, {
            params: {
                source: JSON.stringify(query),
                source_content_type: "application/json",
            },
        })
        .then((res) => {
            let arrTmp = [];

            res.data.hits.hits.forEach((element) => {
                let objTmp = {};
                let arrDescSec = [];
                objTmp["label"] = element._source.nombre;
                objTmp["value"] = element._source.nombre;
                objTmp["busquese"] = element._source.busquese;
                objTmp["vease"] = element._source.vease;

                element._source.descriptores_secundarios.forEach((subElement) => {
                    if (!(subElement.nombre === "nullnull" && subElement.busquese)) {  // Excluir si nombre es "nullnull" y tiene un campo "busquese"
                        arrDescSec.push({
                            label: subElement.nombre,
                            value: subElement.nombre,
                            busquese: subElement.busquese,
                            vease: subElement.vease,
                        });
                    }
                });

                // ORDENA LOS DESCRIPTORES SECUNDARIOS POR ORDEN ALFABÉTICO
                arrDescSec.sort((a, b) => a.label.localeCompare(b.label));
                
                objTmp["descriptoresSecundarios"] = arrDescSec;
                arrTmp.push(objTmp);
            });

            // ORDENA LOS DESCRIPTORES PRINCIPALES POR ORDEN ALFABÉTICO
            arrTmp.sort((a, b) => a.label.localeCompare(b.label));
            return arrTmp;
        });
}