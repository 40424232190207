import deepEqual from "deep-equal";

export function getInitialState() {
    return {
        savedResults: [],
        searchText: "",
        isSearchTextVisible: true,
        selectedFilters: {
            startDate: "",
            endDate: "",
            norms: "",
            processType: "",
            providenceType: "",
            category: "",
            principalDescriptors: "",
            secondDescriptors: "",
            matter: "",
            font: "",
            content: "",
            rol: "",
            idType: "",
            idNumber: "",
            name: "",
            tem: "",
            expediente: "",
            hechosRelevantesDemanda: "",
            hechosRelevantesContestacionDemanda: "",
            pretensionesDemanda: "",
            themeProblems: "",
            problems: "",
        },
        filtersListValues: {
            processesList: null,
            providencesList: null,
            categoriesList: null,
            descriptorsList: null,
            restrictionsList: null,
            rolsList: null,
            identificationsList: null,
            fontsList: null,
            mattersList: null,
            temList: null,
            principalDescriptorsList: null,
            secondDescriptorsList: null,
            contentList: null,
            contentType: null,
            themeProblemsList: null,
            problemsList: null,
        },
        showFooter: true,
        searchResults: null,
        dateResults: null,
        searchDocumentText: "",
        loadingResults: false,
        searchTrigger: false,
        sortType: {},
        resultsViewPage: 1,
        savedBorradores: [],
        savedFiltersEraser: {
            hechosRelevantesDemanda: [],
            hechosRelevantesContestacionDemanda: [],
            pretensionesDemanda: [],
        },
        tabSelected: 1,
    };
}

export function setInputSearchText(state, action) {
    const { input_text, visible } = action;

    return {
        ...state,
        searchText: input_text,
        isSearchTextVisible: visible
    };
}

export function cleanInputSearchText(state, action) {
    return {
        ...state,
        searchText: "",
    };
}

export function updateFilters(state, action) {
    const { filters } = action;

    return {
        ...state,
        selectedFilters: filters,
    };
}

export function updateFilter(state, action) {
    const { filter } = action;

    return {
        ...state,
        selectedFilters: {
            ...state.selectedFilters,
            ...filter,
        },
    };
}

export function setFiltersListValues(state, action) {
    const { options } = action;
    return {
        ...state,
        filtersListValues: options,
    };
}

export function toggleFooter(state) {
    return {
        ...state,
        showFooter: !state.showFooter,
    };
}

export function saveSearchResults(state, action) {
    const { results } = action;
    return {
        ...state,
        searchResults: results,
    };
}

export function saveDateResults(state, action) {
    const { dateResults } = action;
    return {
        ...state,
        dateResults: dateResults,
    };
}

export function saveResults(state, action) {
    const { result } = action;
    if (
        state.savedResults.some((savedResult) => savedResult._id === result._id)
    ) {
        return {
            ...state,
        };
    } else {
        return {
            ...state,
            savedResults: [...state.savedResults, result],
        };
    }
}

export function saveBorradores(state, action) {
    const { result } = action;
    if (
        state.savedBorradores.some(
            (savedBorrador) => savedBorrador._id === result._id
        )
    ) {
        return {
            ...state,
        };
    } else {
        return {
            ...state,
            savedBorradores: [...state.savedBorradores, result],
        };
    }
}

export function saveBorradoresConcepto(state, action) {
    const { result } = action;
    if (
        state.saveBorradoresConcepto.some(
            (savedBorradorConcepto) => savedBorradorConcepto._id === result._id
        )
    ) {
        return {
            ...state,
        };
    } else {
        return {
            ...state,
            saveBorradoresConcepto: [...state.saveBorradoresConcepto, result],
        };
    }
}

export function deleteResult(state, action) {
    const resultId = action.savedResult._id;

    //state.savedResults.splice(state.savedResults.indexOf(result))
    return {
        ...state,
        savedResults: state.savedResults.filter(
            (savedResult) => savedResult._id !== resultId
        ),
    };
}

export function deleteBorrador(state, action) {
    const borradorId = action.savedBorrador._id;

    return {
        ...state,
        savedBorradores: state.savedBorradores.filter(
            (savedBorrador) => savedBorrador._id !== borradorId
        ),
    };
}

export function deleteBorradorKey(state, action) {
    const { idBorrador, key } = action;

    const borradorIndex = state.savedBorradores.findIndex(
        (savedBorrador) => savedBorrador._id === idBorrador
    );

    if (borradorIndex >= 0) {
        const newBorrador = { ...state.savedBorradores[borradorIndex] };
        delete newBorrador[key];

        return {
            ...state,
            savedBorradores: [
                ...state.savedBorradores.slice(0, borradorIndex),
                newBorrador,
                ...state.savedBorradores.slice(borradorIndex + 1),
            ],
        };
    } else {
        return state;
    }
}

export function deleteSavedBorradorKeyPosition(state, action) {
    const { idBorrador, key, elementToDelete } = action;

    const borradorIndex = state.savedBorradores.findIndex(
        (savedBorrador) => savedBorrador._id === idBorrador
    );

    if (borradorIndex >= 0) {
        const newBorrador = { ...state.savedBorradores[borradorIndex] };

        newBorrador[key] = newBorrador[key].filter(
            (obj) => !deepEqual(obj, elementToDelete)
        );

        return {
            ...state,
            savedBorradores: [
                ...state.savedBorradores.slice(0, borradorIndex),
                newBorrador,
                ...state.savedBorradores.slice(borradorIndex + 1),
            ],
        };
    } else {
        return state;
    }
}

export function deleteAllBorrador(state, action) {
    return {
        ...state,
        savedBorradores: [],
    };
}

export function editBorradorKey(state, action) {
    const { idBorrador, key, value } = action;

    const borradorIndex = state.savedBorradores.findIndex(
        (savedBorrador) => savedBorrador._id === idBorrador
    );

    if (borradorIndex >= 0) {
        const newBorrador = { ...state.savedBorradores[borradorIndex] };
        newBorrador[key] = value;

        return {
            ...state,
            savedBorradores: [
                ...state.savedBorradores.slice(0, borradorIndex),
                newBorrador,
                ...state.savedBorradores.slice(borradorIndex + 1),
            ],
        };
    } else {
        return state;
    }
}

export function editSavedBorradorKeyPosition(state, action) {
    const { idBorrador, key, elementToDelete } = action;

    const borradorIndex = state.savedBorradores.findIndex(
        (savedBorrador) => savedBorrador._id === idBorrador
    );

    if (borradorIndex >= 0) {
        const newBorrador = { ...state.savedBorradores[borradorIndex] };

        newBorrador[key] = newBorrador[key].filter(
            (obj) => !deepEqual(obj, elementToDelete)
        );

        return {
            ...state,
            savedBorradores: [
                ...state.savedBorradores.slice(0, borradorIndex),
                newBorrador,
                ...state.savedBorradores.slice(borradorIndex + 1),
            ],
        };
    } else {
        return state;
    }
}

export function setDocumentSearchText(state, action) {
    const { documentSearchText } = action;

    return {
        ...state,
        searchDocumentText: documentSearchText,
    };
}

export function setLoadingResults(state) {
    //  console.log(`Estado actual ${state.loadingResults}`)
    return {
        ...state,
        loadingResults: !state.loadingResults,
    };
}

export function setLoadingResultsFalse(state) {
    // console.log(`Estado actual ${state.loadingResults}`)
    return {
        ...state,
        loadingResults: false,
    };
}

export function setLoadingResultsTrue(state) {
    // console.log(`Estado actual ${state.loadingResults}`)
    return {
        ...state,
        loadingResults: true,
    };
}

export function setSearchTriggerTrue(state) {
    //  console.log(state);
    return {
        ...state,
        searchTrigger: true,
    };
}

export function setSearchTriggerFalse(state) {
    // console.log(state);
    return {
        ...state,
        searchTrigger: false,
    };
}

export function setSortType(state, action) {
    return {
        ...state,
        sortType: action.sortType,
    };
}

export function setResultsViewPage(state, action) {
    return {
        ...state,
        resultsViewPage: action.page,
    };
}

export function updateFiltersEraser(state, action) {
    const { filtersEraser } = action;

    return {
        ...state,
        savedFiltersEraser: filtersEraser,
    };
}

export function setTabSelected(state, action) {
    return {
        ...state,
        tabSelected: action.tab,
    };
}
