export const INPUT_SEARCH_TEXT = "INPUT_SEARCH_TEXT";
export const CLEAN_SEARCH_TEXT = "CLEAN_SEARCH_TEXT";
export const UPDATE_FILTERS = "UPDATE_FILTERS";
export const UPDATE_FILTER = "UPDATE_FILTER";
export const SET_FILTERS_LIST_VALUES = "SET_FILTERS_LIST_VALUES";
export const TOGGLE_FOOTER = "TOGGLE_FOOTER";
export const SAVE_SEARCH_RESULTS = "SAVE_SEARCH_RESULTS";
export const SAVE_DATE_RESULTS = "SAVE_DATE_RESULTS";
export const SAVE_RESULT = "SAVE_RESULT";
export const SAVE_BORRADOR = "SAVE_BORRADOR";
export const SAVE_BORRADOR_CONCEPTO = "SAVE_BORRADOR_CONCEPTO";
export const DELETE_SAVED_RESULT = "DELETE_SAVED_RESULT";
export const DELETE_SAVED_BORRADOR = "DELETE_SAVED_BORRADOR";
export const DELETE_SAVED_BORRADOR_KEY = "DELETE_SAVED_BORRADOR_KEY";
export const DELETE_SAVED_BORRADOR_KEY_POSITION =
    "DELETE_SAVED_BORRADOR_KEY_POSITION";
export const DELETE_ALL_BORRADOR = "DELETE_ALL_BORRADOR";
export const SET_DOC_SEARCH = "SET_DOC_SEARCH";
export const SET_LOADING_RESULTS = "SET_LOADING_RESULTS";
export const SET_LOADING_RESULTS_FALSE = "SET_LOADING_RESULTS_FALSE";
export const SET_LOADING_RESULTS_TRUE = "SET_LOADING_RESULTS_TRUE";
export const SEARCH_TRIGGER_TRUE = "SEARCH_TRIGGER_TRUE";
export const SEARCH_TRIGGER_FALSE = "SEARCH_TRIGGER_FALSE";
export const SET_SORT_FILTER = "SET_SORT_FILTER";
export const SET_RESULTS_VIEW_PAGE = "SET_RESULTS_VIEW_PAGE";
export const EDIT_SAVED_BORRADOR_KEY = "EDIT_SAVED_BORRADOR_KEY";
export const EDIT_SAVED_BORRADOR_KEY_POSITION =
    "EDIT_SAVED_BORRADOR_KEY_POSITION";
export const UPDATE_FILTERS_EREASER = "UPDATE_FILTERS_EREASER";
export const SET_TAB_SELECTED = "SET_TAB_SELECTED";
