import { createStore, applyMiddleware } from "redux";
import reducers from "./reducers";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import persistState from "redux-localstorage";
import { getInitialState } from "./reducers/implementation";

const composeEnhacers = composeWithDevTools({
    trace: true,
});

const store = createStore(
    reducers,
    getInitialState(),
    composeEnhacers(applyMiddleware(thunk), persistState())
);

export default store;
