import React from "react";
import "./footer.scss";
import logo_colombia from "../../../assets/icons/logo_colombia.png";
import potencia_de_la_vida from "../../../assets/icons/potencia_de_la_vida.svg";
import logo_gov from "../../../assets/icons/logo_gov.png";
import twitter from "../../../assets/icons/twiter.png";
import instagram from "../../../assets/icons/instagramIconWhite.png";
import facebook from "../../../assets/icons/facebookIconWhite.png";
import phone from "../../../assets/icons/phone.png";

export default function Footer(props) {
    return (
        <>
      <div className='app-footer'>
        <div className='app-footer-logos'>
          <div className='app-footer__logo-gov'>
            <label
                onClick={() =>
                    window.open(`https://www.gov.co/`)
                    }
                alt="contact"
                >
                <img
                    alt="gov.co"
                    className="logo-gov"
                    src={logo_gov}
                ></img>
            </label>
          </div>
          <div className='app-footer__logo'>
            <label
                onClick={() =>
                    window.open(`/`)
                    }
                alt="contact"
                >
                <img
                    alt="gov.co"
                    className="logo-src"
                    src={logo_colombia}
                ></img>
            </label>
          </div>
        </div>
        <div className='app-footer-separator-1' />
        <div className='app-footer-info-text'>
          <div className='app-footer-info'>
            <h6 className="h6-title">Superintendencia de Sociedades</h6>
            <p>Dirección Avenida El Dorado No. 51-80 / Bogotá - Colombia</p>
            <p>Horario de atención al público: Lunes a Viernes de 8:00am a 5:00pm</p>
            <div className='app-footer-logos-social'>
              <div className='app-footer__logo'>
                <a className="app-footer-logo__container" href='https://twitter.com/SSociedades'>Twitter
                    <img
                        alt="gov.co"
                        className="logo-twitter"
                        src={twitter}
                    ></img>
                </a>
              </div>
              <div className='app-footer__logo'>
                <a className="app-footer-logo__container" href='https://www.instagram.com/ssociedades/'>Instagram
                    <img
                        alt="gov.co"
                        className="logo-instagram"
                        src={instagram}
                    ></img>
                </a>
              </div>
              <div className='app-footer__logo'>
                <a className="app-footer-logo__container" href='https://www.facebook.com/SSociedades/'>Facebook
                    <img
                        alt="gov.co"
                        className="logo-facebook"
                        src={facebook}
                    ></img>
                </a>
              </div>
            </div>
          </div>
          <div className='app-footer-separator' />
          <div className='app-footer-contact'>
            <div className='app-footer-contact-title app-footer__logo'>
                    <img
                        alt="gov.co"
                        className="logo-phone"
                        src={phone}
                    ></img>
              <h6 className="h6-title">Contacto</h6>
            </div>
            <div className='app-footer-contact-info'>
              <p>Teléfono conmutador: 324 57 77 - 220 10 00 </p>
              <p>Centro de Fax 220 10 000, opción 2</p>
              <p>Línea de atención al usuario: 018000114319</p>
              <p>Correo institucional: webmaster@supersociedades.gov.co</p>
              <p>Correo de notificaciones judiciales: <br /> notificacionesjudiciales@supersociedades.gov.co </p>
              <b><a target="_blank" href="https://www.supersociedades.gov.co/Paginas/Mapa-Sitio.aspx">MAPA DEL SITIO</a></b>
              <b><a target="_blank" href="https://www.supersociedades.gov.co/Paginas/Condiciones-de-uso-privacidad.aspx">POLÍTICAS</a></b>
            </div>
          </div>
        </div>
      </div>
    </>
    );
}

