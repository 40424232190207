import {
    INPUT_SEARCH_TEXT,
    CLEAN_SEARCH_TEXT,
    UPDATE_FILTERS,
    UPDATE_FILTER,
    SET_FILTERS_LIST_VALUES,
    TOGGLE_FOOTER,
    SAVE_SEARCH_RESULTS,
    SAVE_DATE_RESULTS,
    SAVE_RESULT,
    SAVE_BORRADOR,
    SAVE_BORRADOR_CONCEPTO,
    DELETE_SAVED_RESULT,
    DELETE_SAVED_BORRADOR,
    DELETE_ALL_BORRADOR,
    SET_DOC_SEARCH,
    SET_LOADING_RESULTS,
    SET_LOADING_RESULTS_FALSE,
    SET_LOADING_RESULTS_TRUE,
    SEARCH_TRIGGER_TRUE,
    SEARCH_TRIGGER_FALSE,
    SET_SORT_FILTER,
    SET_RESULTS_VIEW_PAGE,
    DELETE_SAVED_BORRADOR_KEY,
    DELETE_SAVED_BORRADOR_KEY_POSITION,
    EDIT_SAVED_BORRADOR_KEY,
    EDIT_SAVED_BORRADOR_KEY_POSITION,
    UPDATE_FILTERS_EREASER,
    SET_TAB_SELECTED,
} from "../actions/types";
import {
    getInitialState,
    setInputSearchText,
    cleanInputSearchText,
    updateFilters,
    updateFilter,
    setFiltersListValues,
    toggleFooter,
    saveSearchResults,
    saveDateResults,
    saveResults,
    saveBorradores,
    saveBorradoresConcepto,
    deleteResult,
    deleteBorrador,
    deleteAllBorrador,
    setDocumentSearchText,
    setLoadingResults,
    setLoadingResultsFalse,
    setLoadingResultsTrue,
    setSearchTriggerTrue,
    setSearchTriggerFalse,
    setSortType,
    setResultsViewPage,
    deleteBorradorKey,
    deleteSavedBorradorKeyPosition,
    editBorradorKey,
    editSavedBorradorKeyPosition,
    updateFiltersEraser,
    setTabSelected,
} from "./implementation";

const initialState = getInitialState();

export default function searchReducer(state = initialState, action) {
    switch (action.type) {
        case INPUT_SEARCH_TEXT:
            return setInputSearchText(state, action);
        case CLEAN_SEARCH_TEXT:
            return cleanInputSearchText(state, action);
        case UPDATE_FILTERS:
            return updateFilters(state, action);
        case UPDATE_FILTER:
            return updateFilter(state, action);
        case SET_FILTERS_LIST_VALUES:
            return setFiltersListValues(state, action);
        case TOGGLE_FOOTER:
            return toggleFooter(state);
        case SAVE_SEARCH_RESULTS:
            return saveSearchResults(state, action);
        case SAVE_DATE_RESULTS:
            return saveDateResults(state, action);
        case SAVE_RESULT:
            return saveResults(state, action);
        case SAVE_BORRADOR:
            return saveBorradores(state, action);
        case SAVE_BORRADOR_CONCEPTO:
            return saveBorradoresConcepto(state, action);
        case DELETE_SAVED_RESULT:
            return deleteResult(state, action);
        case DELETE_SAVED_BORRADOR:
            return deleteBorrador(state, action);
        case DELETE_SAVED_BORRADOR_KEY:
            return deleteBorradorKey(state, action);
        case DELETE_SAVED_BORRADOR_KEY_POSITION:
            return deleteSavedBorradorKeyPosition(state, action);
        case DELETE_ALL_BORRADOR:
            return deleteAllBorrador(state, action);
        case EDIT_SAVED_BORRADOR_KEY:
            return editBorradorKey(state, action);
        case EDIT_SAVED_BORRADOR_KEY_POSITION:
            return editSavedBorradorKeyPosition(state, action);
        case SET_DOC_SEARCH:
            return setDocumentSearchText(state, action);
        case SET_LOADING_RESULTS:
            return setLoadingResults(state);
        case SET_LOADING_RESULTS_FALSE:
            return setLoadingResultsFalse(state);
        case SET_LOADING_RESULTS_TRUE:
            return setLoadingResultsTrue(state);
        case SEARCH_TRIGGER_TRUE:
            return setSearchTriggerTrue(state);
        case SEARCH_TRIGGER_FALSE:
            return setSearchTriggerFalse(state);
        case SET_SORT_FILTER:
            return setSortType(state, action);
        case SET_RESULTS_VIEW_PAGE:
            return setResultsViewPage(state, action);
        case UPDATE_FILTERS_EREASER:
            return updateFiltersEraser(state, action);
        case SET_TAB_SELECTED:
            return setTabSelected(state, action);
        default:
            return state;
    }
}
