import {
    PROCESS_TYPE_KEY,
    PROVIDENCE_TYPE_KEY,
    ROL_KEY,
    ID_KEY,
    CONTENT_TYPE_KEY_WORD,
    MATTERS_TYPE_KEY,
    PRINCIPAL_DESCRIPTOR_KEY,
    SECOND_DESCRIPTOR_KEY,
    FONTS_KEY,
    TEM_KEY,
} from "./settings";

import {
    get,
    getThemeProblems,
    getProblemsByTheme,
    getTypeContents,
} from "./filter_values";

export async function InitPage() {
    const optionsProcesses = await get(PROCESS_TYPE_KEY).then((res) => {
        return res;
    });

    const optionsProvidences = await get(PROVIDENCE_TYPE_KEY).then((res) => {
        return res;
    });

    const optionsRols = await get(ROL_KEY).then((res) => {
        return res;
    });

    const optionsIdentifications = await get(ID_KEY).then((res) => {
        return res;
    });

    // Asuntos
    const optionsMatters = await get(MATTERS_TYPE_KEY).then((res) => {
        return res;
    });

    const optionsContent = await get(CONTENT_TYPE_KEY_WORD).then((res) => {
        return res;
    });

    const optionsPrincipalDescriptors = await get(
        PRINCIPAL_DESCRIPTOR_KEY
    ).then((res) => {
        return res;
    });

    const optionsFonts = await get(FONTS_KEY).then((res) => {
        return res;
    });
    const optionsTem = await get(TEM_KEY).then((res) => {
        return res;
    });

    const optionsSecondDescriptors = await get(SECOND_DESCRIPTOR_KEY).then(
        (res) => {
            return res;
        }
    );

    const optionsThemeProblemsList = await getThemeProblems().then((res) => {
        return res;
    });

    const optionsProblems = await getProblemsByTheme().then((res) => {
        return res;
    });

    const contentTypes = await getTypeContents().then((res) => {
        return res;
    });

    return {
        processesList: optionsProcesses,
        providencesList: optionsProvidences,
        rolsList: optionsRols,
        identificationsList: optionsIdentifications,
        contentList: optionsContent,
        contentType: contentTypes,
        mattersList: optionsMatters,
        principalDescriptorsList: optionsPrincipalDescriptors,
        secondDescriptorsList: optionsSecondDescriptors,
        fontsList: optionsFonts,
        temList: optionsTem,
        themeProblemsList: optionsThemeProblemsList,
        problemsList: optionsProblems,
    };
}
